import React from 'react'
import { Link } from 'react-router-dom'

const AppDevelopement = () => {
    return (
        <>
            <div class="container-fluid position-relative p-0">
                <div class="container-fluid py-5 bg-app hero-header mb-5">
                    <div class="container my-5 py-5 px-lg-5">
                        <div class="row g-5 py-5">
                            <div class="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
                                <h1 class="text-white animated zoomIn">App Developement</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb justify-content-center">
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Services</a></li>
                                        <li class="breadcrumb-item text-white active" aria-current="page">App Developement</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/mobile-developement.jpg" />
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">App Developement</h6>
                                <h2 class="mt-2">Mobile App Design Development</h2>
                            </div>
                            <p class="mb-4 text-dark">App development is a multifaceted process that involves careful planning, design, coding, testing, and maintenance. It requires a clear understanding of the target audience, platform-specific requirements, and best practices to create successful and engaging applications. By focusing on user experience, performance, and security, businesses can build apps that meet user needs and stand out in a competitive market.</p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Award Winning</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to='/App-Developement-more'>Read More</Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-facebook-f"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-twitter"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-instagram"></i></Link>
                                <Link class="btn btn-outline-primary btn-square" to=''><i class="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppDevelopement