import React from 'react'
import { Link } from 'react-router-dom'

const SocialMediaMarketing = () => {
  return (
    <>
    <div class="container-fluid position-relative p-0">
        <div class="container-fluid py-5 bg-social hero-header mb-5">
            <div class="container my-5 py-5 px-lg-5">
                <div class="row g-5 py-5">
                    <div class="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
                        <h1 class="text-white animated zoomIn">Social Media Marketing</h1>
                        <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><Link class="text-white" href="#">Home</Link></li>
                                <li class="breadcrumb-item"><Link class="text-white" href="#">Services</Link></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">Social Media Marketing</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-xxl py-5">
        <div class="container px-lg-5">
            <div class="row g-5">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="section-title position-relative mb-4 pb-2">
                        <h6 class="position-relative text-primary ps-4">Social Media Marketing</h6>
                        <h2 class="mt-2 text-primary">The best Social Media Marketing solution with years of experience</h2>
                    </div>
                    <p class="mb-4 text-dark">Social media marketing (SMM) is the use of social media platforms to promote products, services, or brands, engage with target audiences, and drive website traffic. It involves creating and sharing content on social media networks to achieve marketing and branding goals.</p>
                    <div class="row g-3">
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Award Winning</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <Link class="btn btn-primary rounded-pill px-4 me-3"to='/Social-Media-Marketing-More'>Read More</Link>
                        <Link class="btn btn-outline-primary btn-square me-3"to=''><i class="fab fa-facebook-f"></i></Link>
                        <Link class="btn btn-outline-primary btn-square me-3"to=''><i class="fab fa-twitter"></i></Link>
                        <Link class="btn btn-outline-primary btn-square me-3"to=''><i class="fab fa-instagram"></i></Link>
                        <Link class="btn btn-outline-primary btn-square"to=''><i class="fab fa-linkedin-in"></i></Link>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/social-media-marketting.png" />
                </div>
            </div>
        </div>
    </div>

</>
  )
}

export default SocialMediaMarketing