import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <>
            <div class="container-fluid position-relative p-0">
                <div class="container-fluid py-5 bg-about hero-header mb-5">
                    <div class="container my-5 py-5 px-lg-5">
                        <div class="row g-3 py-5">
                            <div class="col-sm-12 text-center p-5" style={{ background: '#21212b73', borderRadius: '5%' }}>
                                <h1 class="text-white animated zoomIn">About Us</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb justify-content-center">
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                                        <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* <!-- Navbar & Hero End --> */}


            {/* <!-- Full Screen Search Start --> */}
            <div class="modal fade" id="searchModal" tabindex="-1">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content" style={{ background: 'rgba(29, 29, 39, 0.7)' }}>
                        <div class="modal-header border-0">
                            <button type="button" class="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body d-flex align-items-center justify-content-center">
                            <div class="input-group" style={{ maxWidth: '600px' }}>
                                <input type="text" class="form-control bg-transparent border-light p-3" placeholder="Type search keyword" />
                                <button class="btn btn-light px-4"><i class="bi bi-search"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Full Screen Search End --> */}


            {/* <!-- About Start --> */}
            <div class="container-xxl py-5 bg-white">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">About Us</h6>
                                <h2 class="mt-2">Welcome to SkylinkWeb – Your Digital Future Starts Here!</h2>
                            </div>
                            <div className="row g-5 mt-3">
                                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="section-title position-relative mb-4 pb-2">
                                        <h6 className="position-relative text-primary ps-4"> Who We Are
                                        </h6>
                                        <h2 className="mt-2 text-primary"> Who We Are
                                        </h2>
                                    </div>
                                    <p className="mb-4 text-dark">  SkylinkWeb is a team of visionary developers, designers, and tech experts dedicated to transforming your ideas into powerful software solutions. With a blend of creativity, technical expertise, and a client-focused approach, we build applications that not only meet but exceed your expectations.</p>

                                    <h6 className="mb-2 text-primary"><i className="fa fa-check text-primary me-2"></i> Our Vision</h6>
                                    <p className="mb-4 text-dark">  We envision a world where technology seamlessly integrates into everyday business processes, unlocking new opportunities and driving growth. Our mission is to provide state-of-the-art web and mobile applications that enhance efficiency, engagement, and overall success.
                                    </p>

                                    <h6 className="mb-2 text-primary"><i className="fa fa-check text-primary me-2"></i>  What We Do</h6>

                                    <p className="mb-4 text-dark">Our expertise spans across a wide range of software development services, designed to meet the diverse needs of businesses in various industries. Our core offerings include:</p>
                                    <ul className="mb-4 text-dark">
                                        <li><b> Web Application Development : </b>  Creating robust, scalable web applications that deliver seamless user experiences and meet complex business requirements. Whether you need an enterprise solution, an e-commerce platform, or a custom tool, we’ve got you covered.</li>
                                        <li><b> Mobile Application Development : </b> Designing and developing high-performance mobile applications for iOS and Android that captivate users and drive engagement. From innovative features to intuitive interfaces, our mobile apps are built to make an impact.</li>
                                        <li><b> UI/UX Design :</b> Crafting engaging and user-friendly interfaces that enhance the user experience and ensure your applications are not only functional but also visually appealing.</li>
                                        <li><b> Software Integration :</b> Connecting disparate systems and applications to streamline processes, enhance functionality, and improve overall efficiency.</li>
                                        <li><b> Consulting Services :</b>  Offering expert advice and strategic guidance to help you navigate your software development journey and achieve your business goals.</li>
                                    </ul>
                                    <h6 className="mb-2 text-primary">Why Choose Us?</h6>
                                    <p className="mb-4 text-dark"></p>
                                    <ul>
                                        <li className="mb-4 text-dark"><b> Innovative Solutions : </b> We stay ahead of the curve by utilizing the latest technologies and best practices to deliver cutting-edge software solutions.</li>
                                        <li className="mb-4 text-dark"><b> Client-Centric Approach :</b> We prioritize your needs and work closely with you to develop solutions tailored to your specific requirements.</li>
                                        <li className="mb-4 text-dark"><b> Experienced Team : </b> Our team consists of skilled professionals with a wealth of experience in web and mobile app development, ensuring top-notch results.</li>
                                        <li className="mb-4 text-dark"><b> Commitment to Quality : </b>   We are dedicated to delivering high-quality software that performs reliably and exceeds your expectations.</li>
                                    </ul>
                                    <h6 className="mb-2 text-primary"> Let’s Build the Future Together</h6>
                                    <p className="mb-4 text-dark">
                                        At SkylinkWeb, we are more than just a development company – we are your technology partner. Whether you’re looking to create a dynamic web application or a groundbreaking mobile app, we are here to bring your vision to life.
                                        <br />
                                        Ready to elevate your business with innovative web and mobile solutions? Contact us today, and let’s start building something extraordinary!</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to="/Contact">Contact Us</Link>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-outline-primary btn-square" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn mt-3" data-wow-delay="0.5s" src="/img/Technology/about-us.jpg" />
                            <img class="img-fluid wow zoomIn mt-5" data-wow-delay="0.5s" src="/img/Technology/about-us-3.png" />
                            <img class="img-fluid wow zoomIn mt-5" data-wow-delay="0.5s" src="/img/Technology/about-us-2.jpg" />
                            <img class="img-fluid wow zoomIn mt-5" data-wow-delay="0.5s" src="/img/Technology/social-media-2.png" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About End --> */}


            {/* <!-- Newsletter Start --> */}
            <div class="container-xxl bg-primary newsletter my-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container px-lg-5">
                    <div class="row align-items-center" style={{ height: '250px' }}>
                        <div class="col-12 col-md-6">
                            <h3 class="text-white">Ready to get started</h3>
                            <small class="text-white">Diam elitr est dolore at sanctus nonumy.</small>
                            <div class="position-relative w-100 mt-3">
                                <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Enter Your Email" style={{ height: '48px' }} />
                                <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i class="fa fa-paper-plane text-primary fs-4"></i></button>
                            </div>
                        </div>
                        <div class="col-md-6 text-center mb-n5 d-none d-md-block">
                            <img class="img-fluid mt-5" style={{ height: '250px' }} src="/img/newsletter.png" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Newsletter End --> */}


            {/* <!-- Team Start --> */}
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                        <h6 class="position-relative d-inline text-primary ps-4">Our Team</h6>
                        <h2 class="mt-2">Meet Our Team Members</h2>
                    </div>
                    <div class="row g-4">
                        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="team-item">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style={{ width: '75px' }}>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-twitter"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-instagram"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                    </div>
                                    <img class="img-fluid rounded w-100" src="img/team-1.jpg" alt="" />
                                </div>
                                <div class="px-4 py-3">
                                    <h5 class="fw-bold m-0">Jhon Doe</h5>
                                    <small>CEO</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="team-item">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style={{ width: '75px' }}>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-twitter"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-instagram"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                    </div>
                                    <img class="img-fluid rounded w-100" src="img/team-2.jpg" alt="" />
                                </div>
                                <div class="px-4 py-3">
                                    <h5 class="fw-bold m-0">Emma William</h5>
                                    <small>Manager</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
                            <div class="team-item">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style={{ width: '75px' }}>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-twitter"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-instagram"></i></a>
                                        <a class="btn btn-square text-primary bg-white my-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                    </div>
                                    <img class="img-fluid rounded w-100" src="img/team-3.jpg" alt="" />
                                </div>
                                <div class="px-4 py-3">
                                    <h5 class="fw-bold m-0">Noah Michael</h5>
                                    <small>Designer</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About