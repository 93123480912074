import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
const Testimonials = () => {
    const testimonialItemStyle = {
        transition: 'transform 0.3s ease, background-color 0.3s ease',
    };

    const testimonialItemHoverStyle = {
        transform: 'scale(1.05)', // Slightly enlarge the item on hover
        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Change the background color
    };

    const testimonialImageStyle = {
        transition: 'transform 0.3s ease',
    };

    const testimonialImageHoverStyle = {
        transform: 'scale(1.1)', // Slightly enlarge the image on hover
    };
    return (
        <>
            <div className="container-xxl bg-primary testimonial py-5 my-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5 px-lg-5">
                    <OwlCarousel className="owl-carousel testimonial-carousel" items={1} autoplay loop margin={10}>
                        <div className="testimonial-item bg-transparent border rounded text-white p-4">
                            <i className="fa fa-quote-left fa-2x mb-3"></i>
                            <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam</p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial-1.jpg" style={{ width: '50px', height: '50px' }} alt="Testimonial 1" />
                                <div className="ps-3">
                                    <h6 className="text-white mb-1">Client Name</h6>
                                    <small>Profession</small>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item bg-transparent border rounded text-white p-4">
                            <i className="fa fa-quote-left fa-2x mb-3"></i>
                            <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam</p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial-2.jpg" style={{ width: '50px', height: '50px' }} alt="Testimonial 2" />
                                <div className="ps-3">
                                    <h6 className="text-white mb-1">Client Name</h6>
                                    <small>Profession</small>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item bg-transparent border rounded text-white p-4">
                            <i className="fa fa-quote-left fa-2x mb-3"></i>
                            <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam</p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial-3.jpg" style={{ width: '50px', height: '50px' }} alt="Testimonial 3" />
                                <div className="ps-3">
                                    <h6 className="text-white mb-1">Client Name</h6>
                                    <small>Profession</small>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item bg-transparent border rounded text-white p-4">
                            <i className="fa fa-quote-left fa-2x mb-3"></i>
                            <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam</p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial-4.jpg" style={{ width: '50px', height: '50px' }} alt="Testimonial 4" />
                                <div className="ps-3">
                                    <h6 className="text-white mb-1">Client Name</h6>
                                    <small>Profession</small>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </>
    )
}

export default Testimonials