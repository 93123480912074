import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeLayout from './Components/HomeLayout';
import HomePage from './Components/HomePage';
import About from './Components/About';
import Service from './Components/Service';
import Contact from './Components/Contact';
import Project from './Components/Project';
import Error404 from './Components/Error404';
import WebDesigning from './Components/WebDesigning';
import OurTeam from './Components/OurTeam';
import WebDesigningMore from './Components/WebDesigningMore';
import SeoOptimization from './Components/SeoOptimization';
import AppDevelopement from './Components/AppDevelopement';
import AppDevelopementMore from './Components/AppDevelopementMore';
import SeoOptimizationMore from './Components/SeoOptimizationMore';
import SocialMediaMarketing from './Components/SocialMediaMarketing';
import SocialMediaMarketingMore from './Components/SocialMediaMarketingMore';
import EmailMarketingMore from './Components/EmailMarketingMore';
import EmailMarketting from './Components/EmailMarketting';
import PpcAdvertising from './Components/PpcAdvertising';
import PpcAdvertisingMore from './Components/PpcAdvertisingMore';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomeLayout />}>
            <Route index element={<HomePage />} />
            <Route path='/About' element={<About />} />
            <Route path='/Service' element={<Service />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path='/Project' element={<Project />} />
            <Route path='/Web-Designing' element={<WebDesigning />} />
            <Route path='/Web-Designing-more' element={<WebDesigningMore />} />
            <Route path='/Seo-Optimization' element={<SeoOptimization />} />
            <Route path='/Seo-Optimization-more' element={<SeoOptimizationMore />} />
            <Route path='/Social-Media-Marketing' element={<SocialMediaMarketing />} />
            <Route path='/Social-Media-Marketing-More' element={<SocialMediaMarketingMore />} />
            <Route path='/Email-Marketing' element={<EmailMarketting />} />
            <Route path='/Ppc-Advertising' element={<PpcAdvertising />} />
            <Route path='/Ppc-Advertising-more' element={<PpcAdvertisingMore />} />
            <Route path='/Email-Marketing-More' element={<EmailMarketingMore />} />
            <Route path='/App-Developement' element={<AppDevelopement />} />
            <Route path='/App-Developement-more' element={<AppDevelopementMore />} />
            <Route path='/Our-Team' element={<OurTeam />} />
          </Route>
          <Route path='/*' element={<Error404 />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
