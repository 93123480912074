import React from 'react'
import { Link } from 'react-router-dom'

const PpcAdvertising = () => {
    return (
        <>
            <div className="container-fluid position-relative p-0">
                <div className="container-fluid py-5 bg-ppc hero-header mb-5">
                    <div className="container my-5 py-5 px-lg-5">
                        <div className="row g-5 py-5">
                            <div class="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
                                <h1 className="text-white animated zoomIn">PPC Advertising</h1>
                                <hr className="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                                        <li className="breadcrumb-item"><a className="text-white" href="#">Services</a></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">PPC Advertising</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/ppc-advertising.png" alt="PPC Advertising" />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">
                                <h6 className="position-relative text-primary ps-4">PPC Advertising</h6>
                                <h2 className="mt-2 text-primary">Maximize Your ROI with Effective PPC Campaigns</h2>
                            </div>
                            <p className="mb-4 text-dark">
                                PPC (Pay-Per-Click) advertising is a digital marketing strategy where advertisers pay each time their ad is clicked. It's a powerful way to drive traffic, generate leads, and increase sales. With PPC, you have full control over your ad budget and can target specific demographics to ensure your ads reach the right audience.
                            </p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Cost-Effective Advertising</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Highly Targeted</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Instant Traffic</h6>
                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Detailed Analytics</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Improved ROI</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Scalable Campaigns</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <Link className="btn btn-primary rounded-pill px-4 me-3" to='/Ppc-Advertising-more'>Learn More</Link>
                                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-outline-primary btn-square" href="#"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PpcAdvertising
