import React from 'react'
import { Link } from 'react-router-dom'
import Testimonials from './Testimonials'
import OurTeam from './OurTeam'
import Service from './Service'
import LanguageSlide from './LanguageSlide'

const HomePage = () => {
    return (
        <>
            <div class="container--fluid py-5 bg-home hero-header mb-5">
                <div class="container my-5 py-5 px-lg-5">
                    <div class="row g-1 py-5">
                        <div class="col-sm-6 text-center text-lg-start p-5" style={{ background: '#21212b73', borderRadius: '5%' }}>
                            <h1 class="text-white mb-4 animated zoomIn">We Create Innovative Platforms to Deliver Exceptional Experiences for Our Clients</h1>
                            <p class="text-white pb-3 animated zoomIn">At the heart of our mission is the commitment to develop cutting-edge platforms that not only meet but exceed expectations. We craft solutions that empower businesses, drive growth, and create lasting impact. From concept to delivery, our focus is on quality, innovation, and unparalleled service.</p>

                            <a href="" class="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft">Free Quote</a>
                            <Link to="/Contact" class="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</Link>
                        </div>
                        {/* <div class="col text-center text-lg-start">
                            <img class="img-fluid" src="img/hero.png" alt="" />
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="container-fluid position-relative p-0">
                <div class="container-fluid py-2 bg-web-2 hero-header mb-3">
                    <div class="container my-2 py-2 px-lg-5">
                        <div class="row g-5 py-2">
                            <div class="col-12 text-center">
                                <h1 class="text-primary pt-4 animated zoomIn">SEO Optimization</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/seo-optimization.jpg" />
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">SEO Optimization</h6>
                                <h2 class="mt-2 text-primary">The best SEO solution with years of experience</h2>
                            </div>
                            <p class="mb-4 text-dark">SEO stands for Search Engine Optimization. It is the practice of optimizing a website or web page to increase its visibility and ranking on search engine results pages (SERPs) like Google, Bing, and Yahoo. The main goal of SEO is to attract organic (non-paid) traffic to a website by improving its search engine rankings.</p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Award Winning</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to='/Seo-Optimization-more'>Read More</Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-facebook-f"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-twitter"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-instagram"></i></Link>
                                <Link class="btn btn-outline-primary btn-square" to=''><i class="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid position-relative p-0 mt-5">
                <div class="container-fluid py-2 bg-web hero-header mb-3">
                    <div class="container my-2 py-2 px-lg-5">
                        <div class="row g-5 py-2">
                            <div class="col-12 text-center">
                                <h1 class="text-primary pt-4 animated zoomIn">Web Designing</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Web Designing</h6>
                                <h2 class="mt-2 text-primary">Crafting Beautiful & Functional Websites</h2>
                            </div>
                            <p class="mb-4 text-dark">Innovative web design solutions to enhance your brand and captivate your audience.</p>
                            <p class="mb-4 text-dark">
                                At SkyLinkWeb, we specialize in creating visually appealing and functionally robust websites tailored to meet your unique business needs. From engaging custom designs to seamless user experiences, our team is dedicated to bringing your vision to life. Explore our comprehensive web design services to see how we can elevate your brand online.
                            </p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Custom Web Design</h6>
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Responsive Design</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Brand Identity Design</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>UX/UI Design</h6>
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>E-Commerce Solutions</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Website Maintenance</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to='/Web-Designing-more'>Read More</Link>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-outline-primary btn-square" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/Web-Developement.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid position-relative p-0 mt-5">
                <div class="container-fluid py-2 bg-web-2 hero-header mb-3">
                    <div class="container my-2 py-2 px-lg-5">
                        <div class="row g-5 py-2">
                            <div class="col-12 text-center">
                                <h1 class="text-primary pt-4 animated zoomIn">Social Media Marketing</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/social-media-marketting.png" />
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Social Media Marketing</h6>
                                <h2 class="mt-2 text-primary">The best Social Media Marketing solution with years of experience</h2>
                            </div>
                            <p class="mb-4 text-dark">Social media marketing (SMM) is the use of social media platforms to promote products, services, or brands, engage with target audiences, and drive website traffic. It involves creating and sharing content on social media networks to achieve marketing and branding goals.</p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Award Winning</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to='/Social-Media-Marketing-More'>Read More</Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-facebook-f"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-twitter"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-instagram"></i></Link>
                                <Link class="btn btn-outline-primary btn-square" to=''><i class="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid position-relative p-0 mt-5">
                <div class="container-fluid py-2 bg-web hero-header mb-3">
                    <div class="container my-2 py-2 px-lg-5">
                        <div class="row g-5 py-2">
                            <div class="col-12 text-center">
                                <h1 class="text-primary pt-4 animated zoomIn">App Development</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">App Developement</h6>
                                <h2 class="mt-2">Mobile App Design Development</h2>
                            </div>
                            <p class="mb-4 text-dark">App development is a multifaceted process that involves careful planning, design, coding, testing, and maintenance. It requires a clear understanding of the target audience, platform-specific requirements, and best practices to create successful and engaging applications. By focusing on user experience, performance, and security, businesses can build apps that meet user needs and stand out in a competitive market.</p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Award Winning</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to='/App-Developement-more'>Read More</Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-facebook-f"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-twitter"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3" to=''><i class="fab fa-instagram"></i></Link>
                                <Link class="btn btn-outline-primary btn-square" to=''><i class="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/mobile-developement.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid position-relative p-0 mt-5">
                <div class="container-fluid py-2 bg-web-2 hero-header mb-3">
                    <div class="container my-2 py-2 px-lg-5">
                        <div class="row g-5 py-2">
                            <div class="col-12 text-center">
                                <h1 class="text-primary pt-4 animated zoomIn">Email marketing</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/email-marketing.jpg" />
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Email marketing</h6>
                                <h2 class="mt-2 text-primary">Reach Your Audience with Impactful Campaigns</h2>
                            </div>
                            <p class="mb-4 text-dark">
                                Email marketing is a digital marketing strategy that involves sending emails to a group of recipients to promote products, services, or content, build relationships, and drive engagement. It is one of the most effective and direct forms of communication with potential and existing customers.
                            </p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Targeted Campaigns</h6>
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Personalization</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Measurable Results</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Builds Customer Loyalty</h6>
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Real-Time Communication</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Automated Workflows</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to='/Email-Marketing-More'>Read More</Link>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-outline-primary btn-square" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid position-relative p-0 mt-5">
                <div class="container-fluid py-2 bg-web hero-header mb-3">
                    <div class="container my-2 py-2 px-lg-5">
                        <div class="row g-5 py-2">
                            <div class="col-12 text-center">
                                <h1 class="text-primary pt-4 animated zoomIn">PPC Advertising</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/ppc-advertising.png" alt="PPC Advertising" />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">
                                <h6 className="position-relative text-primary ps-4">PPC Advertising</h6>
                                <h2 className="mt-2 text-primary">Maximize Your ROI with Effective PPC Campaigns</h2>
                            </div>
                            <p className="mb-4 text-dark">
                                PPC (Pay-Per-Click) advertising is a digital marketing strategy where advertisers pay each time their ad is clicked. It's a powerful way to drive traffic, generate leads, and increase sales. With PPC, you have full control over your ad budget and can target specific demographics to ensure your ads reach the right audience.
                            </p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Cost-Effective Advertising</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Highly Targeted</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Instant Traffic</h6>
                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Detailed Analytics</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Improved ROI</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Scalable Campaigns</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <Link className="btn btn-primary rounded-pill px-4 me-3" to='/Ppc-Advertising-more'>Learn More</Link>
                                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-outline-primary btn-square" href="#"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid position-relative p-0 mt-5">
                <div class="container-fluid py-2 bg-web-2 hero-header mb-3">
                    <div class="container my-2 py-2 px-lg-5">
                        <div class="row g-5 py-2">
                            <div class="col-12 text-center">
                                <h1 class="text-primary pt-4 animated zoomIn">About Us</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          {/* <!-- About Start --> */}
          <div class="container-xxl py-5 bg-white">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">About Us</h6>
                                <h2 class="mt-2">Welcome to SkylinkWeb – Your Digital Future Starts Here!</h2>
                            </div>
                            <div className="row g-5 mt-3">
                                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="section-title position-relative mb-4 pb-2">
                                        <h6 className="position-relative text-primary ps-4"> Who We Are
                                        </h6>
                                        <h2 className="mt-2 text-primary"> Who We Are
                                        </h2>
                                    </div>
                                    <p className="mb-4 text-dark">  SkylinkWeb is a team of visionary developers, designers, and tech experts dedicated to transforming your ideas into powerful software solutions. With a blend of creativity, technical expertise, and a client-focused approach, we build applications that not only meet but exceed your expectations.</p>

                                    <h6 className="mb-2 text-primary"><i className="fa fa-check text-primary me-2"></i> Our Vision</h6>
                                    <p className="mb-4 text-dark">  We envision a world where technology seamlessly integrates into everyday business processes, unlocking new opportunities and driving growth. Our mission is to provide state-of-the-art web and mobile applications that enhance efficiency, engagement, and overall success.
                                    </p>

                                    <h6 className="mb-2 text-primary"><i className="fa fa-check text-primary me-2"></i>  What We Do</h6>

                                    <p className="mb-4 text-dark">Our expertise spans across a wide range of software development services, designed to meet the diverse needs of businesses in various industries. Our core offerings include:</p>
                                    <ul className="mb-4 text-dark">
                                        <li><b> Web Application Development : </b>  Creating robust, scalable web applications that deliver seamless user experiences and meet complex business requirements. Whether you need an enterprise solution, an e-commerce platform, or a custom tool, we’ve got you covered.</li>
                                        <li><b> Mobile Application Development : </b> Designing and developing high-performance mobile applications for iOS and Android that captivate users and drive engagement. From innovative features to intuitive interfaces, our mobile apps are built to make an impact.</li>
                                        <li><b> UI/UX Design :</b> Crafting engaging and user-friendly interfaces that enhance the user experience and ensure your applications are not only functional but also visually appealing.</li>
                                        <li><b> Software Integration :</b> Connecting disparate systems and applications to streamline processes, enhance functionality, and improve overall efficiency.</li>
                                        <li><b> Consulting Services :</b>  Offering expert advice and strategic guidance to help you navigate your software development journey and achieve your business goals.</li>
                                    </ul>
                                    <h6 className="mb-2 text-primary">Why Choose Us?</h6>
                                    <p className="mb-4 text-dark"></p>
                                    <ul>
                                        <li className="mb-4 text-dark"><b> Innovative Solutions : </b> We stay ahead of the curve by utilizing the latest technologies and best practices to deliver cutting-edge software solutions.</li>
                                        <li className="mb-4 text-dark"><b> Client-Centric Approach :</b> We prioritize your needs and work closely with you to develop solutions tailored to your specific requirements.</li>
                                        <li className="mb-4 text-dark"><b> Experienced Team : </b> Our team consists of skilled professionals with a wealth of experience in web and mobile app development, ensuring top-notch results.</li>
                                        <li className="mb-4 text-dark"><b> Commitment to Quality : </b>   We are dedicated to delivering high-quality software that performs reliably and exceeds your expectations.</li>
                                    </ul>
                                    <h6 className="mb-2 text-primary"> Let’s Build the Future Together</h6>
                                    <p className="mb-4 text-dark">
                                        At SkylinkWeb, we are more than just a development company – we are your technology partner. Whether you’re looking to create a dynamic web application or a groundbreaking mobile app, we are here to bring your vision to life.
                                        <br />
                                        Ready to elevate your business with innovative web and mobile solutions? Contact us today, and let’s start building something extraordinary!</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to="/Contact">Contact Us</Link>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-outline-primary btn-square" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn mt-3" data-wow-delay="0.5s" src="/img/Technology/about-us.jpg" />
                            <img class="img-fluid wow zoomIn mt-5" data-wow-delay="0.5s" src="/img/Technology/about-us-3.png" />
                            <img class="img-fluid wow zoomIn mt-5" data-wow-delay="0.5s" src="/img/Technology/about-us-2.jpg" />
                            <img class="img-fluid wow zoomIn mt-5" data-wow-delay="0.5s" src="/img/Technology/social-media-2.png" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About End --> */}


            {/* <!-- Newsletter Start --> */}
            <div class="container-xxl bg-primary newsletter my-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container px-lg-5">
                    <div class="row align-items-center" style={{ height: '250px' }}>
                        <div class="col-12 col-md-6">
                            <h3 class="text-white">Ready to get started</h3>
                            <small class="text-white">Diam elitr est dolore at sanctus nonumy.</small>
                            <div class="position-relative w-100 mt-3">
                                <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Enter Your Email" style={{ height: '48px' }} />
                                <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i class="fa fa-paper-plane text-primary fs-4"></i></button>
                            </div>
                        </div>
                        <div class="col-md-6 text-center mb-n5 d-none d-md-block">
                            <img class="img-fluid mt-5" style={{ height: '250px' }} src="img/newsletter.png" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Newsletter End --> */}


            {/* <!-- Service Start --> */}
            <div class="container-fluid p-0" style={{ marginTop: '200px' }}>
                <div class="container-fluid position-relative p-0">
                    <div class="container-fluid py-2 bg-web hero-header mb-3">
                        <div class="container my-2 py-2 px-lg-5">
                            <div class="row g-5 py-2">
                                <div class="col-12 text-center">
                                    <h1 class="text-primary pt-1 animated zoomIn">Services</h1>
                                    <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* <!-- Service Start --> */}
                <div class="container-xxl py-5">
                    <div class="container px-lg-5">
                        <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                            <h6 class="position-relative d-inline text-primary ps-4">Our Services</h6>
                            <h2 class="mt-2">What Solutions We Provide</h2>
                        </div>
                        <div class="row g-4">
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-search fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">SEO Optimization</h5>
                                    <p>SEO stands for Search Engine Optimization. It is the practice of optimizing a website .</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/Seo-Optimization'>Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-globe fa-2x"></i>
                                        <img src="/img/Technology/" alt="" />
                                    </div>
                                    <h5 class="mb-3">Web Design</h5>
                                    <p>Innovative web design solutions to enhance your brand and captivate your audience.</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to="/Web-Designing">Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fab fa-facebook fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">Social Media Marketing</h5>
                                    <p>Social media marketing (SMM) is the use of social media platforms to promote products</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/Social-Media-Marketing'>Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-envelope fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">Email Marketing</h5>
                                    <p>Email marketing is a digital marketing strategy that involves sending emails to a group of recipients to promote products, services, or content, build relationships, and drive engagement.</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/Email-Marketing'>Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-mouse-pointer fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">PPC Advertising</h5>
                                    <p>PPC (Pay-Per-Click) advertising is a digital marketing strategy where advertisers pay each time their ad is clicked. It's a powerful way to drive traffic, generate leads, and increase sales.</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/Ppc-Advertising'>Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-mobile fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">App Development</h5>
                                    <p>App development is the process of creating software applications that run on mobile devices such as smartphones and tablets.</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/App-Developement'>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Service End --> */}
            </div>
            {/* <!-- Service End --> */}

            <LanguageSlide />
            {/* <!-- Portfolio Start --> */}
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                        <h6 class="position-relative d-inline text-primary ps-4">Our Projects</h6>
                        <h2 class="mt-2">Recently Launched Projects</h2>
                    </div>
                    <div class="row mt-n2 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="col-12 text-center">
                            <ul class="list-inline mb-5" id="portfolio-flters">
                                <li class="btn px-3 pe-4 active" data-filter="*">All</li>
                                <li class="btn px-3 pe-4" data-filter=".first">Design</li>
                                <li class="btn px-3 pe-4" data-filter=".second">Development</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row g-4 portfolio-container">
                        <div class="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.1s">
                            <div class="position-relative rounded overflow-hidden">
                                <img class="img-fluid w-100" src="img/portfolio-1.jpg" alt="" />
                                <div class="portfolio-overlay">
                                    <a class="btn btn-light" href="img/portfolio-1.jpg" data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                                    <div class="mt-auto">
                                        <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                        <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.3s">
                            <div class="position-relative rounded overflow-hidden">
                                <img class="img-fluid w-100" src="img/portfolio-2.jpg" alt="" />
                                <div class="portfolio-overlay">
                                    <a class="btn btn-light" href="img/portfolio-2.jpg" data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                                    <div class="mt-auto">
                                        <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                        <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.6s">
                            <div class="position-relative rounded overflow-hidden">
                                <img class="img-fluid w-100" src="img/portfolio-3.jpg" alt="" />
                                <div class="portfolio-overlay">
                                    <a class="btn btn-light" href="img/portfolio-3.jpg" data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                                    <div class="mt-auto">
                                        <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                        <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.1s">
                            <div class="position-relative rounded overflow-hidden">
                                <img class="img-fluid w-100" src="img/portfolio-4.jpg" alt="" />
                                <div class="portfolio-overlay">
                                    <a class="btn btn-light" href="img/portfolio-4.jpg" data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                                    <div class="mt-auto">
                                        <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                        <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.3s">
                            <div class="position-relative rounded overflow-hidden">
                                <img class="img-fluid w-100" src="img/portfolio-5.jpg" alt="" />
                                <div class="portfolio-overlay">
                                    <a class="btn btn-light" href="img/portfolio-5.jpg" data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                                    <div class="mt-auto">
                                        <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                        <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.6s">
                            <div class="position-relative rounded overflow-hidden">
                                <img class="img-fluid w-100" src="img/portfolio-6.jpg" alt="" />
                                <div class="portfolio-overlay">
                                    <a class="btn btn-light" href="img/portfolio-6.jpg" data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                                    <div class="mt-auto">
                                        <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                        <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonials />
            <OurTeam />


        </>
    )
}

export default HomePage