import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div class="container-fluid bg-primary text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <div class="container py-5 px-lg-5">
                    <div class="row g-5">
                        <div class="col-md-6 col-lg-3">
                            <h5 class="text-white mb-4">Get In Touch</h5>
                            <p><i class="fa fa-map-marker-alt me-3"></i>Sector 49-Block E sector 3 noida gautam buddha nagar</p>
                            <p><i class="fa fa-phone-alt me-3"></i> <a href="tel:9580011886"  className='text-white'>+91 9580011886</a></p>
                            <p><i class="fa fa-envelope me-3"></i><a href="mailto:support@skylinkweb.co.in" className='text-white'>support@skylinkweb.co.in</a></p>

                            <div class="d-flex pt-2">
                                <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>
                                <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <h5 class="text-white mb-4">Popular Link</h5>
                            <Link class="btn btn-link" to='/About'>About Us</Link>
                            <Link class="btn btn-link" to='/Contact'>Contact Us</Link>
                            <Link class="btn btn-link" to='/'>Privacy Policy</Link>
                            <Link class="btn btn-link" to='/'>Terms & Condition</Link>
                            <Link class="btn btn-link" to='/'>Career</Link>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <h5 class="text-white mb-4">Project Gallery</h5>
                            <div class="row g-2">
                                <div class="col-4">
                                    <img class="img-fluid" src="img/portfolio-1.jpg" alt="Image" />
                                </div>
                                <div class="col-4">
                                    <img class="img-fluid" src="img/portfolio-2.jpg" alt="Image" />
                                </div>
                                <div class="col-4">
                                    <img class="img-fluid" src="img/portfolio-3.jpg" alt="Image" />
                                </div>
                                <div class="col-4">
                                    <img class="img-fluid" src="img/portfolio-4.jpg" alt="Image" />
                                </div>
                                <div class="col-4">
                                    <img class="img-fluid" src="img/portfolio-5.jpg" alt="Image" />
                                </div>
                                <div class="col-4">
                                    <img class="img-fluid" src="img/portfolio-6.jpg" alt="Image" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <h5 class="text-white mb-4">Newsletter</h5>
                            <p>Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulpu</p>
                            <div class="position-relative w-100 mt-3">
                                <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Your Email" style={{ height: '48px' }} />
                                <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i class="fa fa-paper-plane text-primary fs-4"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container px-lg-5">
                    <div class="copyright">
                        <div class="row">
                            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a class="border-bottom" href="https://skylinkweb.co.in/">Skylinkweb</a>, All Right Reserved.
                            </div>
                            <div class="col-md-6 text-center text-md-end">
                                <div class="footer-menu">
                                    <a href="">Home</a>
                                    <a href="">Cookies</a>
                                    <a href="">Help</a>
                                    <a href="">FQAs</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer