import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import LanguageSlide from './LanguageSlide';
const WebDesigningMore = () => {
    return (
        <>
            <div class="container-fluid position-relative p-0">
                <div class="container-fluid py-5 bg-design hero-header mb-5">
                    <div class="container my-5 py-5 px-lg-5">
                        <div class="row g-5 py-5">
                            <div class="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
                                <h1 class="text-white animated zoomIn">Web Designing</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb justify-content-center">
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                                        <li class="breadcrumb-item text-white active" aria-current="page">Web Designing</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl bg-white py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Web Designing</h6>
                                <h2 class="mt-2 text-primary">What is Web Designing?</h2>
                            </div>
                            <p class="mb-4 text-dark">Web designing is the process of creating and designing the layout, visual appearance, and usability of a website. It encompasses various skills and disciplines, including graphic design, user interface (UI) design, user experience (UX) design, and SEO. Web designers aim to create websites that are aesthetically pleasing, user-friendly, and functionally robust.</p>
                        </div>
                    </div>
                    <div class="row g-5 mt-3">
                        <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Key Components</h6>
                                <h2 class="mt-2 text-primary">Key Components of Web Designing :</h2>
                            </div>
                            <p class="mb-4 text-dark">Web designing is the process of creating and designing the layout, visual appearance, and usability of a website. It encompasses various skills and disciplines, including graphic design, user interface (UI) design, user experience (UX) design, and SEO. Web designers aim to create websites that are aesthetically pleasing, user-friendly, and functionally robust.</p>
                            <div class="row g-3">
                                <div class="col-sm-12">
                                    <h6 class="mb-2 text-primary"><i class="fa fa-check text-primary me-2"></i> Layout and Structure : </h6>
                                    <ul className='text-dark'>
                                        <li><b>Wireframes : </b> Simple black-and-white layouts that outline the specific size and placement of page elements, site features, conversion areas, and navigation.</li>
                                        <li><b>Mockups : </b> Detailed design drafts that show what the final website will look like.</li>
                                        <li><b>Responsive Design : </b> Ensuring that the website looks and works well on all devices, including desktops, tablets, and smartphones.</li>
                                    </ul>
                                    <h6 class="mb-2 text-primary"><i class="fa fa-check text-primary me-2"></i> Visual Design : </h6>
                                    <ul className='text-dark'>
                                        <li><b>Color Scheme : </b>  Choosing a set of colors that align with the brand and appeal to the target audience.</li>
                                        <li><b>Typography : </b> Selecting fonts that enhance readability and complement the overall design.</li>
                                        <li><b>Imagery : </b> Using images, graphics, icons, and videos that enhance the visual appeal and convey the intended message.</li>
                                    </ul>
                                    <h6 class="mb-2 text-primary"><i class="fa fa-check text-primary me-2"></i>User Interface (UI) Design : </h6>
                                    <ul className='text-dark'>
                                        <li><b>Navigation : </b> Designing intuitive menus, buttons, and links that help users easily find what they’re looking for.</li>
                                        <li><b>Interactivity : </b> Incorporating elements like hover effects, animations, and interactive forms to enhance user engagement.</li>
                                        <li><b>Accessibility : </b> Ensuring the website is usable by people with various disabilities by following accessibility standards and guidelines (e.g., WCAG).</li>
                                    </ul>
                                    <h6 class="mb-2 text-primary"><i class="fa fa-check text-primary me-2"></i>User Experience (UX) Design : </h6>
                                    <ul className='text-dark'>
                                        <li><b>Usability : </b> Making the website easy to use, with a clear structure and straightforward navigation.</li>
                                        <li><b>Information Architecture : </b> Organizing content in a logical way that helps users find information quickly.</li>
                                        <li><b>Performance Optimization : </b> Ensuring the website loads quickly and runs smoothly, providing a seamless experience for users.</li>
                                    </ul>
                                    <h6 class="mb-2 text-primary"><i class="fa fa-check text-primary me-2"></i> Content Creation : </h6>
                                    <ul className='text-dark'>
                                        <li><b>Copywriting : </b> Writing clear, concise, and compelling text that communicates the intended message and encourages user action.</li>
                                        <li><b>SEO : </b> Optimizing content for search engines to improve visibility and attract organic traffic.</li>
                                    </ul>
                                    <h6 class="mb-2 text-primary"><i class="fa fa-check text-primary me-2"></i> Technical Aspects : </h6>
                                    <ul className='text-dark'>
                                        <li><b>HTML/CSS : </b> Using HTML (HyperText Markup Language) for structuring content and CSS (Cascading Style Sheets) for styling the website.</li>
                                        <li><b>JavaScript : </b> Adding interactivity and dynamic elements to the website.</li>
                                        <li><b>Web Development Tools : </b> Utilizing tools and frameworks like Adobe XD, Sketch, Figma, and others to design and prototype websites.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5 mt-3">
                        <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Importance</h6>
                                <h2 class="mt-2 text-primary">Importance of Web Designing : </h2>
                            </div>
                            <ul className='text-dark'>
                                <li><strong>First Impressions:</strong> The design of a website is often the first interaction a user has with a brand. A well-designed website creates a positive first impression and builds trust.</li>
                                <li><strong>User Experience:</strong> Good web design ensures a smooth and enjoyable user experience, which can lead to higher engagement, longer visit durations, and more conversions.</li>
                                <li><strong>Brand Identity:</strong> A consistent and visually appealing design helps reinforce a brand’s identity and message.</li>
                                <li><strong>SEO:</strong> Effective web design practices, such as proper use of HTML tags, optimized images, and responsive design, contribute to better search engine rankings.</li>
                                <li><strong>Accessibility:</strong> Inclusive design practices ensure that websites are usable by everyone, including people with disabilities, thereby broadening the potential audience.</li>
                                <li><strong>Competitive Advantage:</strong> A professionally designed website can set a business apart from its competitors and convey a sense of professionalism and credibility.</li>
                            </ul>

                        </div>
                    </div>
                    <div class="row g-5 mt-3">
                        <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Conclusion</h6>
                                <h2 class="mt-2 text-primary">Conclusion : </h2>
                            </div>
                            <p class="mb-4 text-dark">Web designing is a multifaceted discipline that involves creating websites that are visually appealing, user-friendly, and technically sound. It combines elements of graphic design, UI/UX design, content creation, and technical development to build websites that effectively communicate the intended message and provide an excellent user experience.</p>
                        </div>
                    </div>
                </div>
            </div>
            <LanguageSlide />
        </>
    )
}

export default WebDesigningMore