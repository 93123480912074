import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
const LanguageSlide = () => {
  return (
    <>
    <div className="container-xxl bg-primary">
                <div className="row p-5">
                    <OwlCarousel items={3} autoplay smartSpeed={10} autoplayTimeout={1000} margin={10} loop>
                        <div className="col">
                            <img src="/img/Technology/html.png" class="img-fluid" alt="" style={{ width: '250px', height: '200px' }} />
                        </div>
                        <div className="col">
                            <img src="/img/Technology/css.png" class="img-fluid" alt="" style={{ width: '250px', height: '200px' }} />
                        </div>
                        <div className="col">
                            <img src="/img/Technology/bootstrap.png" class="img-fluid" alt="" style={{ width: '250px', height: '200px' }} />
                        </div>
                        <div className="col">
                            <img src="/img/Technology/javascript.png" class="img-fluid" alt="" style={{ width: '250px', height: '200px' }} />
                        </div>
                        <div className="col">
                            <img src="/img/Technology/react.png" alt="" class="img-fluid" style={{ width: '250px', height: '200px' }} />
                        </div>
                        <div className="col">
                            <img src="/img/Technology/wordpress.png" class="img-fluid" alt="" style={{ width: '250px', height: '200px' }} />
                        </div>
                    </OwlCarousel>

                </div>
            </div>
    </>
  )
}

export default LanguageSlide