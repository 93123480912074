import React from 'react'

import { Link } from 'react-router-dom';
import LanguageSlide from './LanguageSlide';
const WebDesigning = () => {
    return (
        <>
            <div class="container-fluid position-relative p-0">
                <div class="container-fluid py-5 bg-design hero-header mb-5">
                    <div class="container my-5 py-5 px-lg-5">
                        <div class="row g-5 py-5">
                            <div class="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
                                <h1 class="text-white animated zoomIn">Web Designing</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb justify-content-center">
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Services</a></li>
                                        <li class="breadcrumb-item text-white active" aria-current="page">Web Designing</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/Web-Developement.jpg" />
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Web Designing</h6>
                                <h2 class="mt-2 text-primary">Crafting Beautiful & Functional Websites</h2>
                            </div>
                            <p class="mb-4 text-dark">Innovative web design solutions to enhance your brand and captivate your audience.</p>
                            <p class="mb-4 text-dark">
                                At SkyLinkWeb, we specialize in creating visually appealing and functionally robust websites tailored to meet your unique business needs. From engaging custom designs to seamless user experiences, our team is dedicated to bringing your vision to life. Explore our comprehensive web design services to see how we can elevate your brand online.
                            </p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Custom Web Design</h6>
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Responsive Design</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Brand Identity Design</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>UX/UI Design</h6>
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>E-Commerce Solutions</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Website Maintenance</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to='/Web-Designing-more'>Read More</Link>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-outline-primary btn-square" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <LanguageSlide />
                    </div>
                </div>
            </div>

        </>
    )
}

export default WebDesigning