import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <>
            <div class="container-fluid position-relative p-0">
                <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                    <a href="" class="navbar-brand p-0">
                        <h1 class="m-0"><img src="/skylink_logo.png" alt="" /></h1>
                        {/* <!-- <img src="img/logo.png" alt="Logo"> --> */}
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class="fa fa-bars"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarCollapse" >
                        <div class="navbar-nav ms-auto py-0">
                            <Link to="/" class="nav-item nav-link active">Home</Link>
                            <Link to="/About" class="nav-item nav-link">About</Link>
                            <Link to="/Service" class="nav-item nav-link">Service</Link>
                            <Link to="/Project" class="nav-item nav-link">Project</Link>
                            <div class="nav-item dropdown">
                                <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                <div class="dropdown-menu m-0">
                                    <Link to="/Our-Team" class="dropdown-item">Our Team</Link>
                                </div>
                            </div>
                            <Link to="/Contact" class="nav-item nav-link">Contact</Link>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header