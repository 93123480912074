import React from 'react'
import { Link } from 'react-router-dom'

const EmailMarketting = () => {
    return (
        <>
            <div class="container-fluid position-relative p-0">
                <div class="container-fluid py-5 bg-email hero-header mb-5">
                    <div class="container my-5 py-5 px-lg-5">
                        <div class="row g-5 py-5">
                            <div class="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
                                <h1 class="text-white animated zoomIn">Email marketing</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb justify-content-center">
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                                        <li class="breadcrumb-item"><a class="text-white" href="#">Services</a></li>
                                        <li class="breadcrumb-item text-white active" aria-current="page">Email marketing</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/email-marketing.jpg" />
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">Email marketing</h6>
                                <h2 class="mt-2 text-primary">Reach Your Audience with Impactful Campaigns</h2>
                            </div>
                            <p class="mb-4 text-dark">
                            Email marketing is a digital marketing strategy that involves sending emails to a group of recipients to promote products, services, or content, build relationships, and drive engagement. It is one of the most effective and direct forms of communication with potential and existing customers.
                            </p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Targeted Campaigns</h6>
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Personalization</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Measurable Results</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Builds Customer Loyalty</h6>
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Real-Time Communication</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Automated Workflows</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3" to='/Email-Marketing-More'>Read More</Link>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-outline-primary btn-square" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailMarketting