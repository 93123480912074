import React from 'react'
import { Link } from 'react-router-dom'

const SeoOptimization = () => {
    return (
        <>
            <div class="container-fluid position-relative p-0">
                <div class="container-fluid py-5 bg-seo hero-header mb-5">
                    <div class="container my-5 py-5 px-lg-5">
                        <div class="row g-5 py-5">
                            <div class="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
                                <h1 class="text-white animated zoomIn">SEO Optimization</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb justify-content-center">
                                        <li class="breadcrumb-item"><Link class="text-white" href="#">Home</Link></li>
                                        <li class="breadcrumb-item"><Link class="text-white" href="#">Services</Link></li>
                                        <li class="breadcrumb-item text-white active" aria-current="page">SEO Optimization</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="/img/Technology/seo-optimization.jpg" />
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">SEO Optimization</h6>
                                <h2 class="mt-2 text-primary">The best SEO solution with years of experience</h2>
                            </div>
                            <p class="mb-4 text-dark">SEO stands for Search Engine Optimization. It is the practice of optimizing a website or web page to increase its visibility and ranking on search engine results pages (SERPs) like Google, Bing, and Yahoo. The main goal of SEO is to attract organic (non-paid) traffic to a website by improving its search engine rankings.</p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Award Winning</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <Link class="btn btn-primary rounded-pill px-4 me-3"to='/Seo-Optimization-more'>Read More</Link>
                                <Link class="btn btn-outline-primary btn-square me-3"to=''><i class="fab fa-facebook-f"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3"to=''><i class="fab fa-twitter"></i></Link>
                                <Link class="btn btn-outline-primary btn-square me-3"to=''><i class="fab fa-instagram"></i></Link>
                                <Link class="btn btn-outline-primary btn-square"to=''><i class="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SeoOptimization