import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      subject: subject,
      message: message,
    };

    emailjs.send(
      'service_oorhiqo', 
      'template_uw2zfaa', 
      templateParams,
      'qbAMbgqPqSn5HQ4W3' 
    )
    .then((result) => {
      toast.success('Message sent successfully!');
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
    }, (error) => {
      toast.error('An error occurred, please try again later.');
    });
  };

  return (
    <>
      <div className="container-fluid py-5 bg-contact hero-header mb-5">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
              <h1 className="text-white animated zoomIn">Contact Us</h1>
              <hr className="bg-white mx-auto mt-0" style={{ width: '90px' }} />
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                  <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">Contact</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-4 d-flex justify-content-center">
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div className="service-item d-flex flex-column justify-content-center text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-envelope fa-2x"></i>
            </div>
            <h5 className="m-0">support@skylinkweb.co.in</h5>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
          <div className="service-item d-flex flex-column justify-content-center text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-phone fa-2x"></i>
            </div>
            <h5 className="m-3">+91 9580011886</h5>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="position-relative d-inline text-primary ps-4">Contact Us</h6>
                <h2 className="mt-2">Contact For Any Query</h2>
              </div>
              <div className="wow fadeInUp" data-wow-delay="0.3s">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          required
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: '150px' }}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 bg-web-2">
        <div className="container my-2 py-2 px-lg-5">
          <div className="row g-2 py-2">
            <div className="col-12 text-center">
              <h1 className="text-primary animated zoomIn pt-3">Contact Us Directly</h1>
              <hr className="bg-white mx-auto mt-0" style={{ width: '90px' }} />
              <h5 className="text-dark">We are here to assist you with any questions or inquiries you may have. Feel free to reach out to us through the following methods</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5 bg-white mt-4">
        <div className="row mt-2 mb-5">
          <div className="col bg-primary p-5">
            <h1 className="text-white">
              Feel Free To <br />
              Contact Us
            </h1>
          </div>
          <div className="col text-end p-5">
            <a href="tel:+919580011886">
              <div
                className="text-white d-flex align-items-center justify-content-center"
                style={{
                  height: '100px', width: '100px',
                  background: '#2124b1', borderRadius: '50%', textAlign: 'center', fontSize: '2.5rem', padding: '10px'
                }}>
                <i className="fa fa-phone"></i>
              </div>
            </a>
          </div>
          <div className="col text-end p-5">
            <a href="https://wa.me/919580011886">
              <div
                className="text-white d-flex align-items-center justify-content-center"
                style={{
                  height: '100px', width: '100px',
                  background: '#2124b1', borderRadius: '50%', textAlign: 'center', fontSize: '3.5rem', padding: '10px'
                }}>
                <i className="fab fa-whatsapp"></i>
              </div>
            </a>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Contact;
