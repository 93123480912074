import React from 'react'
import { Link } from 'react-router-dom'

const Service = () => {
    return (
        <>
            <div class="container-fluid bg-white p-0">
                <div class="container-fluid position-relative p-0">
                    <div class="container-fluid py-5 bg-services hero-header mb-5">
                        <div class="container my-5 py-5 px-lg-5">
                            <div class="row g-5 py-5">
                                <div class="col-sm-12 text-center p-5" style={{background:'#21212b73',borderRadius:'5%'}}>
                                    <h1 class="text-white animated zoomIn">Services</h1>
                                    <hr class="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                                            <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                                            <li class="breadcrumb-item text-white active" aria-current="page">Services</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* <!-- Service Start --> */}
                <div class="container-xxl py-5">
                    <div class="container px-lg-5">
                        <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                            <h6 class="position-relative d-inline text-primary ps-4">Our Services</h6>
                            <h2 class="mt-2">What Solutions We Provide</h2>
                        </div>
                        <div class="row g-4">
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-search fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">SEO Optimization</h5>
                                    <p>SEO stands for Search Engine Optimization. It is the practice of optimizing a website </p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/Seo-Optimization'>Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                         <i class="fa fa-globe fa-2x"></i>
                                        <img src="/img/Technology/" alt="" />
                                    </div>
                                    <h5 class="mb-3">Web Design</h5>
                                    <p>Innovative web design solutions to enhance your brand and captivate your audience.</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to="/Web-Designing">Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fab fa-facebook fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">Social Media Marketing</h5>
                                    <p>Social media marketing (SMM) is the use of social media platforms to promote products .</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/Social-Media-Marketing'>Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-envelope fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">Email Marketing</h5>
                                    <p>Email marketing is a digital marketing strategy that involves sending emails to a group of recipients to promote products, services, or content, build relationships, and drive engagement.</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/Email-Marketing'>Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-mouse-pointer fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">PPC Advertising</h5>
                                    <p>PPC (Pay-Per-Click) advertising is a digital marketing strategy where advertisers pay each time their ad is clicked. It's a powerful way to drive traffic, generate leads, and increase sales.</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/Ppc-Advertising'>Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                                <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div class="service-icon flex-shrink-0">
                                        <i class="fa fa-mobile fa-2x"></i>
                                    </div>
                                    <h5 class="mb-3">App Development</h5>
                                    <p>App development is the process of creating software applications that run on mobile devices such as smartphones and tablets.</p>
                                    <Link class="btn px-3 mt-auto mx-auto" to='/App-Developement'>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Service End --> */}
            </div>
        </>
    )
}

export default Service